import React from "react";
function Services() {  
    return (
    <section id="services" className="services">
    <div className="container" style={{ height: '540px' }}>
      <div className="section-title">
        <h2>学习小工具</h2>
        <p>这里是一些我的学习小工具。</p>
      </div>
      <div className="row">
        <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
          <div className="icon"><i className="bi bi-card-checklist"></i></div>
          <h4 className="title"><a href="mathpractise/print.html">打印口算单</a></h4>
          <p className="description">每天练一遍，你也来一遍。</p>
        </div>
        <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
          <div className="icon"><i className="bi bi-binoculars"></i></div>
          <h4 className="title"><a href="preview.png">打印预习单</a></h4>
          <p className="description">预习使我学的更好</p>
        </div>
        <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
          <div className="icon"><i className="bi bi-brightness-high"></i></div>
          <h4 className="title"><a href="pinyin_format1.jpg">打印拼音单</a></h4>
          <p className="description">b p m f我最棒！</p>
        </div>
        <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
          <div className="icon"><i className="bi bi-table"></i></div>
          <h4 className="title"><a href="/oralcalc/">口算生成器</a></h4>
          <p className="description">Base on 高大科技</p>
        </div>
        <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
          <div className="icon"><i className="bi bi-filetype-ttf"></i></div>
          <h4 className="title"><a href="/resources/engwriting/舒窈衡水体.ttf">衡水体字体</a></h4>
          <p className="description"></p>
        </div>
        <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
          <div className="icon"><i className="bi bi-alphabet-uppercase"></i></div>
          <h4 className="title"><a href="/resources/engwriting/舒窈英文衡水体 - 字帖描红模板.docx">衡水体字帖模板</a></h4>
          <p className="description"></p>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Services;