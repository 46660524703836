import React from "react";
//pinyin4js@1.3.16 or latest
import pinyin4js from 'pinyin4js';
import TextField from '@mui/material/TextField';
import { Button } from "@mui/material";
import { useState, useEffect } from "react";
import { getNextReviewDate } from "../common/utils.tsx";
import { uploadContent, isRemoteAvaliable } from '../common/ossUtils.tsx';
import { getSettings, Page as Setting, buildFileUrl } from '../common/setting.tsx';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import copy from "copy-to-clipboard";

const X_STEP = 65;
const Y_STEP = 15;

const fieldTemplate = {
    "name": "field1",
    "type": "text",
    "content": "",
    "position": {
        "x": 10,
        "y": 10
    },
    "width": 60,
    "height": 10,
    fontName: "SimSun",
}


function Services() {
    // use useState to get input value
    const [inputValue, setInputValue] = useState('');
    const [outputValues, setOutputValues] = useState<string[]>([]);
    const [resultValue, setResultValue] = useState('');
    const [isInfoOpen, setIsInfoOpen] = useState(false);
    const [testTime, setTestTime] = useState<Dayjs | null>(dayjs(new Date()));

    function printPinyin(str) {
        copy(str);
        setResultValue(str);
        setInputValue("");
        localStorage.setItem("statusChinese", JSON.stringify({ input: "", result: str }));
        setTimeout(() => {
            window.open('https://danmoshui.com/', 'damoshui');
        }, 500);
        return;
        // TODO despared : switch to danmoshui
        // let tempAry: string[] = [];
        // str.split(' ').forEach(function (item) {
        //     if (item == '') return;
        //     if (item.length > 0) {
        //         var pinYinStr = pinyin4js.convertToPinyinString(item.replaceAll('*', ''), ' ', pinyin4js.WITH_TONE_MARK).split(' ');
        //         var pinYinIndex = 0;
        //         var shouldOutput = true;
        //         var itemStr = '';
        //         // loop every charactors in the string
        //         for (var i = 0; i < item.length; i++) {
        //             if (item.charAt(i) == '*') {
        //                 shouldOutput = false;
        //             } else {
        //                 if (shouldOutput) {
        //                     itemStr += pinyin4js.convertToPinyinString(pinYinStr[pinYinIndex++], ' ', pinyin4js.WITH_TONE_MARK) + "(　　)";
        //                 } else {
        //                     itemStr += item.charAt(i);
        //                     pinYinIndex++;
        //                     shouldOutput = true;
        //                 }
        //             }
        //         }
        //         tempAry.push(itemStr);
        //     }
        // });
        // setOutputValues(tempAry);
        // setResultValue(inputValue);
        // console.log(outputValues);
        // var printWin = window.open('', 'printWin', 'width=1000,height=1000,top=0,left=0,toolbar=no,menubar=no,scrollbars=no,resizable=no,location=no,status=no');
        // if (printWin) {
        //     printWin.document.write('<style>body{font-size: 20px;}</style>');
        //     printWin.document.write("<style>@page {size: A4;font} table{font-size:14pt;} @media print{.no-print{display: none !important;}}</style>")
        //     printWin.document.write("<button onclick='window.print();window.close()' class='no-print' id='start'>打 印</button>")
        //     printWin.document.write("<button onclick='window.close()' class='no-print' id='start'>关 闭</button>")
        //     printWin.document.write('<div style="display:flex;flex-wrap:wrap;"><div style="flex: 0 0 33%; margin: 0 0 15px 0">');
        //     printWin.document.write(tempAry.join('</div><div style="flex: 0 0 33%; margin: 0 0 15px 0">'));
        //     printWin.document.write('</div></div>');
        // } else {
        //     alert('无法打开新窗口，请检查浏览器设置或尝试其他操作。');
        // }
        // print();
    }

    function loadRemoteWords() {
        if (isRemoteAvaliable()) {
            return fetch(buildFileUrl('remoteChineseWords.json')
                , { cache: 'no-cache' })
                .then(response => response.json())
        }
        return Promise.resolve([]);
    }

    function saveRecord(str) {
        let temp = "";
        let items = str.split(' ')
        let tempStr = '';
        items.forEach((item) => {
            tempStr += item.indexOf('X') > -1 ? item + '\r\n' : '';
        });
        if (tempStr.length > 0) {
            if (!confirm('本次测验出下列错字，是否确认？\r\n' + tempStr)) return;
        } else {
            if (!confirm('本次测验没有错字，是否确认？')) return;
        }
        loadRemoteWords().then(remoteRecord => {
            let newWords: any[] = [];
            items.forEach((item) => {
                let currentTime = testTime ? testTime.toDate().getTime() : new Date().getTime();
                if (item.length > 0) {
                    let index = remoteRecord.findIndex(word => word.word == item.replaceAll("X", ""));
                    if (index !== -1) {
                        remoteRecord[index].reviewCnt += 1;
                        remoteRecord[index].lastWrongCnt = item.indexOf('X') > -1 ? remoteRecord[index].reviewCnt : remoteRecord[index].lastWrongCnt;
                        remoteRecord[index].wrongCnt = item.indexOf('X') > -1 ? remoteRecord[index].wrongCnt + 1 : remoteRecord[index].wrongCnt;
                        remoteRecord[index].nextReviewDate = getNextReviewDate(
                            (remoteRecord[index].lastWrongCnt ? Math.min(remoteRecord[index].reviewCnt - remoteRecord[index].lastWrongCnt, 3 + 1) : 5)
                            + (remoteRecord[index].reviewCnt - remoteRecord[index].lastWrongCnt + 1)
                            , currentTime
                        );
                        remoteRecord[index].lastReviewDate = currentTime;
                        if (remoteRecord[index].originWord.split(",").findIndex(temp => temp == item) == -1) {
                            remoteRecord[index].originWord = remoteRecord[index].originWord + "," + item;
                        }
                    }
                    else {
                        newWords.push(
                            {
                                word: item.replaceAll("X", ""),
                                addDate: currentTime,
                                lastReviewDate: currentTime,
                                wrongCnt: item.indexOf('X') > -1 ? 1 : 0,
                                reviewCnt: 1,
                                lastWrongCnt: item.indexOf('X') > -1 ? 1 : 0,
                                nextReviewDate: getNextReviewDate((item.indexOf('X') == -1 ? 5 : 0) + 1
                                    , currentTime),
                                originWord: item
                            });
                    }

                }
            });
            return Promise.resolve(remoteRecord.concat(newWords));
        }).then(remoteWords => {
            let settings = getSettings();
            if (isRemoteAvaliable()) {
                return uploadContent((settings.domain ? (settings.domain + '/') : '') + 'remoteChineseWords.json', JSON.stringify(remoteWords)).then(() => {
                    localStorage.setItem('remoteChineseWordsBackup', JSON.stringify(remoteWords));
                    alert('保存成功');
                    setResultValue('');
                    localStorage.removeItem("statusChinese");
                });
            }
        })
    }

    function genPinyin() {
        setInputValue('Loading...');
        loadRemoteWords().then((data) => {
            setInputValue('');
            if (!data) return Promise.resolve(null);
            data = data.filter(item => !item.nextReviewDate || (item.nextReviewDate < new Date().getTime())).sort((a, b) => {
                return (a.nextReviewDate ? a.nextReviewDate : (new Date().getTime() - 1)) - (b.nextReviewDate ? b.nextReviewDate : (new Date().getTime() - 1));
            })
            let tempStr = "";
            data.forEach((item, index) => {
                tempStr += `${item.word.replaceAll("X", "")} `;
            });
            setInputValue(tempStr);
        });
    }

    function showWordList() {
        loadRemoteWords()
            .then((data: any) => {
                if (!data || !Array.isArray(data)) return;

                data.sort((a, b) => {
                    return (b.lastReviewDate ? b.lastReviewDate : 0) - (a.lastReviewDate ? a.lastReviewDate : 0);
                })

                var printWin = window.open('', 'printWin', 'width=1000,height=1000,top=0,left=0,toolbar=no,menubar=no,scrollbars=no,resizable=no,location=no,status=no');
                if (printWin) {
                    printWin.document.write('<style>body{font-size: 20px;}</style>');
                    printWin.document.write("<style>@page {size: A4;font} table{font-size:14pt;} @media print{.no-print{display: none !important;}}</style>")
                    printWin.document.write("<button onclick='window.print();window.close()' class='no-print' id='start'>打 印</button>")
                    printWin.document.write("<button onclick='window.close()' class='no-print' id='start'>关 闭</button>")
                    printWin.document.write('<div style="display:flex;flex-wrap:wrap;width:100%">');

                    printWin.document.write('<div style="flex: 0 0 25%; margin: 0 0 15px 0">');
                    printWin.document.write('Words');
                    printWin.document.write('</div>');
                    printWin.document.write(('<div style="flex: 0 0 25; margin: 0 0 15px 0">'));
                    printWin.document.write('Last Reviewed Date');
                    printWin.document.write(('</div>'));
                    printWin.document.write(('<div style="flex: 0 0 25%; margin: 0 0 15px 0">'));
                    printWin.document.write('Reviewed Times');
                    printWin.document.write(('</div>'));
                    printWin.document.write(('<div style="flex: 0 0 25%; margin: 0 0 15px 0">'));
                    printWin.document.write(('</div>'));
                    data.forEach((item, index) => {
                        printWin.document.write('<div style="flex: 0 0 25%; margin: 0 0 15px 0">');
                        printWin.document.write(item.word.replaceAll("X", "").replaceAll("+", ""));
                        printWin.document.write(('</div>'));
                        printWin.document.write(('<div style="flex: 0 0 25%; margin: 0 0 15px 0">'));
                        printWin.document.write(new Date(item.lastReviewDate).toLocaleString());
                        printWin.document.write(('</div>'));
                        printWin.document.write(('<div style="flex: 0 0 25%; margin: 0 0 15px 0">'));
                        printWin.document.write(item.reviewCnt || 0);
                        printWin.document.write(('</div>'));
                        printWin.document.write(('<div style="flex: 0 0 25%; margin: 0 0 15px 0">'));
                        printWin.document.write(('</div>'));
                    })
                    printWin.document.write('</div></div>');
                } else {
                    alert('无法打开新窗口，请检查浏览器设置或尝试其他操作。');
                }
            });

    }

    function toggleIsInfoOpen() {
        setIsInfoOpen(!isInfoOpen);
    }

    useEffect(() => {
        let status = JSON.parse(localStorage.getItem("statusChinese") || '{}')
        if (status.input) {
            setInputValue(status.input);
        }
        if (status.result) {
            setResultValue(status.result);
        }
    }, []);

    // more detail methods in test
    // WITH_TONE_NUMBER--数字代表声调，WITHOUT_TONE--不带声调，WITH_TONE_MARK--带声调
    return (
        <section id="pinyinwriting" className="services" >
            <div className="section-title">
                <h2>字词默写</h2>
                <p></p>
            </div>
            <div style={
                { height: '540px', width: '90%', margin: '0 auto' }
            }>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <a href="#" onClick={showWordList}> Show history</a>
                </div>
                <div style={
                    { height: '', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }
                }>
                    <Button style={{ marginTop: '10px' }} variant="contained" color="primary" onClick={() => { genPinyin() }}
                        disabled={resultValue != ''}
                    > 出题 </Button>
                </div>
                <TextField style={styles.textField} onChange={(e) => { setInputValue(e.target.value); }}
                    label="直接复制到淡墨水，可以自行添加新词。"
                    multiline
                    value={inputValue}
                    rows={4}
                    disabled={resultValue != ''}
                />
                <div style={
                    { display: 'flex', justifyContent: 'right', marginTop: '-30px', marginRight: '10px', color: 'darkgray' }
                }> 需默写 {inputValue.replace(/\s|\+/g, '').length} 字</div>
                <div style={
                    { height: '', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }
                }>
                    <Button style={{ marginTop: '10px' }} variant="contained" color="primary" onClick={() => { printPinyin(inputValue) }}

                    > 复制并跳转到生成试卷 (出题使用淡墨水) </Button>
                    <div style={{ marginTop: '10px' }}>
                        <a href='https://danmoshui.com/' target="_blank" >淡墨水</a>
                        <a target="_blank" style={{ marginLeft: '10px' }} onClick={toggleIsInfoOpen}><i className="bi bi-info-circle"></i></a>
                    </div>
                    <img src='/danmoshuiShot.png' alt="info" style={{ display: isInfoOpen ? 'block' : 'none' }} />
                </div>
                <TextField style={styles.textField} onChange={(e) => { setResultValue(e.target.value) }}
                    label="复制听写汉字，以空格分隔。写错的字前面加“X”号，如 X你好 大X家，将自动记录错字。"
                    multiline
                    value={resultValue}
                    rows={4}
                />
                <div style={
                    { display: 'flex', justifyContent: 'right', marginTop: '-30px', marginRight: '10px', color: 'darkgray' }
                }> 需默写 {resultValue.replace(/\s|(.\+)/g, '').length} 字</div>
                <div style={
                    { height: '', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px' }
                }> 测试时间
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div>
                            <DateTimePicker value={dayjs(new Date())} onChange={(newValue) => setTestTime(newValue)} value={testTime} />
                        </div>
                    </LocalizationProvider>
                    <Button style={{ marginTop: '10px' }} variant="contained" color="primary" onClick={() => { saveRecord(resultValue) }}
                        disabled={resultValue == ''}> 保存记录 </Button>
                </div>

            </div>
        </section>
    )
}
export default Services;

// styles
const styles = {
    // 100% width mui text field and two rows height
    textField: {
        width: '100%',
        marginTop: '10px',

    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: '10px',
    },
    item: {
        flex: '0 0 25%',
    },
    printContainer: {
        display: 'flex',
        marginTop: '10px',
        lexWrap: 'wrap',
        overflow: 'scroll',
        maxHeight: '80%',
    }
}