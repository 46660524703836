import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import { useState } from "react";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

let settings;
function init() {
    let str = localStorage.getItem('settings');
    if (str === null) {
        alert('请先设置');
    } else {
        settings = JSON.parse(str);
    }
}
function getSettings() {
    if (!settings) {
        init();
    }
    return settings;
}

function buildFileUrl(fileName){
    let settings = getSettings();
    return settings.databaseUrl
    + (settings.databaseUrl.endsWith('/') ? '' : '/')
    + (settings.domain ? (settings.domain + '/') : '')
    + fileName;
}

function Page() {
    const [open, setOpen] = React.useState(false);
    const [databaseUrl, setDatabaseUrl] = useState('');
    const [region, setRegion] = useState('');
    const [accessKeyId, setAccessKeyId] = useState('');
    const [accessKeySecret, setAccessKeySecret] = useState('');
    const [bucket, setBucket] = useState('');
    const [domain, setDomain] = useState('');

    const handleOpen = () => {
        getSettings();
        if (settings) {
            setDatabaseUrl(settings.databaseUrl);
            setRegion(settings.region);
            setBucket(settings.bucket);
            setAccessKeyId(settings.accessKeyId);
            setAccessKeySecret(settings.accessKeySecret);
            setDomain(settings.domain);
        }
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
        localStorage.setItem("settings", JSON.stringify({
            "databaseUrl": databaseUrl,
            "region": region,
            "bucket": bucket,
            "accessKeyId": accessKeyId,
            "accessKeySecret": accessKeySecret,
            "domain": domain,
        }))
        init();
    }



    return (
        <div>
            <Button onClick={handleOpen} >Settings</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    <TextField id="" label="SSO Base URL" variant="standard" style={styles.textField}
                        onChange={event => { setDatabaseUrl(event.target.value) }} value={databaseUrl} autoComplete="off"
                        required/>
                    <TextField id="" label="Region" variant="standard" style={styles.textField}
                        onChange={event => { setRegion(event.target.value) }} value={region} autoComplete="off"
                        required/>
                    
                    <TextField id="" label="Bucket" variant="standard" style={styles.textField}
                        onChange={event => { setBucket(event.target.value) }} value={bucket} autoComplete="off"
                        required/>
                    <TextField id="" label="AccessKeyId" variant="standard" style={styles.textField}
                        onChange={event => { setAccessKeyId(event.target.value) }} value={accessKeyId} autoComplete="off"
                        required/>
                    <TextField id="" label="AccessKeySecret" variant="standard" style={styles.textField}
                        onChange={event => { setAccessKeySecret(event.target.value) }} value={accessKeySecret} autoComplete="off"
                    required />
                    <TextField id="" label="Domain" variant="standard" style={styles.textField}
                        onChange={event => { setDomain(event.target.value) }} value={domain} autoComplete="off"
                     />
                    <div style={styles.buttonDiv}>
                    <Button variant="contained" onClick={handleClose} style={styles.button} className=''>Save</Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export { Page, getSettings, buildFileUrl };

const styles = {
    textField: {
        width: '100%'
    },
    button:{
        
    },
    buttonDiv: {
        display: 'flex',
        alignItems: 'center', 
        justifyContent: 'center', 
        marginTop: '10px'
    }
}