import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import PlayCircle from '@mui/icons-material/PlayCircle';
import { speakWord } from "../common/utils.tsx";

function Page(props:{
    data: any[],
    fields: { keys: string[] , names: string[] },
    onClose: Function,
    open: boolean,
    playField?: string,
    title?: string
}) {
    const handleClose = () => props.onClose();
    let width = Math.floor(100 / props.fields.keys.length);

    return (
        <div>
            <Modal style={{ marginLeft: '300px', width: '80%' }}
                open={props.open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styles.box}>
                    <h1>{props.title}</h1>
                    <div style={{ display: "flex", flexWrap: "wrap", maxHeight: "500px", overflowY: "auto", width: "100%" }}>
                        {props.fields.names.map((name: any, index) => (
                            <div style={{ flex: '0 0 ' + width + '%' }}>{name}</div>
                        ))}
                        {props.data.map((item: any) =>
                            props.fields.keys.map((key: any) =>
                                <div style={{ flex: '0 0 ' + width + '%' }}>{item[key]}
                                
                        {key == props.playField ?
                            <IconButton onClick={() => {
                                speakWord(item[key]);
                            }}><PlayCircle color="primary"/> </IconButton>
                            : null
                        }
                                </div>
                            )
                        )}
                    </div>
                    <div style={styles.buttonDiv}>
                        <Button variant="contained" onClick={handleClose} style={styles.button} className=''>Close</Button>
                    </div>
                </Box>
            </Modal>
        </div>
    )

}

export default Page;

const styles = {
    textField: {
        width: '100%'
    },
    button: {

    },
    buttonDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '10px'
    },
    box: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    }
}