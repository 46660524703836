
import "aos/dist/aos.css";
import React, { useState, useEffect, lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import "./resource/css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "boxicons/css/boxicons.css";
import 'boxicons';
//import "boxicons/dist/boxicons"

import "glightbox/dist/css/glightbox.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import FaultDatabase from "./pages/faultdatabase/faultdatabase";
import Services from "./pages/services/services";
import PinyinWriting from "./pages/pinyinWriting/pinyinWriting.tsx";
import Spelling from "./pages/spelling/spelling.tsx";
import initPage from "./util/main.js";
// import { AccLine } from "./pages/spelling/reports/reports.tsx";
// TODO 有问题，临时删除
//const FaultHome = lazy(() => import('./pages/faultdatabase/home.js'));
// 增加代码到Route              <Route path="/fault" element={<FaultHome />} />

//import mainjs from "./util/main.js";

import ProfileImg from "./resource/img/profile-img.jpg";


const Init = () => {
  const initScript = () => {
    initPage();
  }
  useEffect(() => {
    initScript()
  }, [])
  return null
}

function App() {
  return (
    <div className="App">
      <i className="bi bi-list mobile-nav-toggle d-xl-none"></i>
      <header id="header">
        <div className="d-flex flex-column">
          <div className="profile">
            <img src={ProfileImg} alt="" className="img-fluid rounded-circle" />
            <h1 className="text-light"><a href="index.html">沈空以<br />Sky </a></h1>
          </div>
          <nav id="navbar" className="nav-menu navbar">
            <ul>
              <li><a href="#hero" className="nav-link scrollto active"><i className="bx bx-home"></i> <span>Home</span></a></li>
              <li><a href="#services" className="nav-link scrollto"><i className="bx bx-server"></i> <span>学习小工具</span></a></li>
              <li><a href="#pinyinwriting" className="nav-link scrollto"><i className="bi bi-type"></i> <span>字词默写</span></a></li>
              <li><a href="#spelling" className="nav-link scrollto"> <i className="bi bi-pencil-square"></i> <span>单词听写</span></a></li>
              <li><a href="#faultdatabase" className="nav-link scrollto"><i className="bi bi-cart-x"></i> <span>错题本</span></a></li>
            </ul>
          </nav>
        </div>
      </header>
      <section id="hero" className="d-flex flex-column justify-content-center align-items-center">
        <div className="hero-container" data-aos="fade-in">
          <h1>沈 空以</h1>
          <p>我要 <span className="typed" data-typed-items="创制未来世界 with coding"></span></p>
        </div>
      </section>
      <main id="main">
        <Router>
          <Suspense fallback={<div>Loading...</div>}>
            <Services />
            <PinyinWriting />
            <Spelling />
            <FaultDatabase />
            {/* <AccLine/> */}
          </Suspense>
        </Router>
      </main>
      <footer id="footer">
        <div className="container">
          <div className="copyright">
            &copy; Copyright <strong><span>Sky</span></strong>
          </div>
          <div className="credits">
            Designed by <a href="https://skyshen.co.jp/">Sky and His Daddy</a>
          </div>
        </div>
      </footer>
      <a href="#" className="back-to-top d-flex align-items-center justify-content-center">
        <i className="bi bi-arrow-up-short"></i>
      </a>
      <Init />
    </div>
  );
}

export default App;
