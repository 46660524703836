import React from "react";
import TextField from '@mui/material/TextField';
import { Button } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { getSettings, Page as Setting, buildFileUrl } from '../common/setting.tsx';
import { getNextReviewDate, speakWord } from "../common/utils.tsx";
import { uploadContent, isRemoteAvaliable } from '../common/ossUtils.tsx';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import History from '../common/history.tsx';
import IconButton from '@mui/material/IconButton';
import PlayCircle from '@mui/icons-material/PlayCircle';

const WORD_COUNT_PERTEST = window.location.host == 'skyshen.com.cn' ? 10 : 2;

let index = -1;
let words: any[] = [];
let currentWord: any = {};
let failWords: any[] = [];



function mergeWords(data: any[], temp: any[], key: string, mergeItems?: string[]) {

    // most old reviewed 15 words
    // merge words with localStorage, merge key is word, delete merged elements from temp
    data = data.map(inword => {

        let index = temp.findIndex(tempWord => tempWord[key] === inword[key]);
        if (index !== -1) {
            if (mergeItems) {
                mergeItems.forEach(item => {
                    inword[item] = temp[index][item];
                });
            } else {
                Object.assign(inword, temp[index]);
            }
        }
        return inword;
    })
    temp.forEach(tempWord => {
        let index = data.findIndex(inword => inword[key] === tempWord[key]);
        if (index === -1) {
            data.push(tempWord);
        }
    })
    return data;
}

function saveLocalResult(status) {
    localStorage.setItem('status', JSON.stringify(status));
    localStorage.setItem('statusDate', new Date().getTime().toString());
}

function saveRemoteWords(words) {
    return loadRemoteWords().then(remoteWords => {
        let settings = getSettings();
        let newRemoteWords = mergeWords(remoteWords, words, 'word', ["reviewCnt", "lastReviewDate", "nextReviewDate", "wrongCnt"]);
        localStorage.setItem('remoteWords', JSON.stringify(newRemoteWords));
        if (isRemoteAvaliable()) {
            return uploadContent((settings.domain ? (settings.domain + '/') : '') + 'remoteWords.json', JSON.stringify(newRemoteWords)).then(() => {
                localStorage.setItem('remoteWordsBackup', JSON.stringify(words));
                localStorage.removeItem("remoteWords");
                localStorage.removeItem('status');
                alert('保存成功');
            });
        }
    })
}


function saveHistory(words) {
    let settings = getSettings();
    if (isRemoteAvaliable()) {
        return uploadContent((settings.domain ? (settings.domain + '/') : '') + 'history/remoteWords' + new Date().getTime() + '.json', JSON.stringify(
            { date: new Date().getTime(), words }
        ));
    }
}
/**
 * load words from remote database
 * 
 * @returns Promise<any>
 */
function loadRemoteWords() {
    if (localStorage.getItem('remoteWords')) {
        let temp = JSON.parse(localStorage.getItem('remoteWords') || '[]');
        return Promise.resolve(temp);
    }
    if (isRemoteAvaliable()) {
        let settings = getSettings();
        return fetch(buildFileUrl('remoteWords.json'), { cache: 'no-cache' })
            .then(response => response.json())
    }
    return Promise.resolve([]);
}

function loadWords(includeWords: boolean, includePhrase: boolean): Promise<any> {
    let settings = getSettings();
    if (!settings) return Promise.resolve(null);
    if (!settings.databaseUrl) {
        alert('no database setting')
        return Promise.resolve(null);
    }
    let result: Promise<any[] | never[]> = Promise.resolve([]);
    if (includeWords) {
        result = result.then(data => {
            return fetch(buildFileUrl("words.json") , { cache: 'no-cache' })
                .then(response => response.json())
                .then(temp => data.concat(temp));
        })
    }
    if (includePhrase) {
        result = result.then(data => {
            return fetch(buildFileUrl("phrases.json"), { cache: 'no-cache' })
                .then(response => response.json())
                .then(temp => temp.map(item => { item.isPhrase = true; return item; }))
                .then(temp => data.concat(temp));
        })
    }
    result = result.then(data => {
        if (!data || !Array.isArray(data) || data.length == 0) return Promise.resolve([]);
        return loadRemoteWords().then((temp) => {
            return temp.filter(word =>
                (includePhrase && word.isPhrase) ||
                (includeWords && !word.isPhrase)
            );
        })
            .then(temp => mergeWords(data, temp, 'word', ["reviewCnt", "lastReviewDate", "nextReviewDate", "wrongCnt"]));
    });
    return result;
}

function Page() {
    const inputRef = React.useRef<HTMLInputElement | null>(null);
    const [inputValue, setInputValue] = useState('Abc...');
    const [word, setWord] = useState('Press Start!');
    const [desc, setDesc] = useState('');
    const [isCorrect, setIsCorrect] = useState(true);
    const [correctCnt, setCorrectCnt] = useState(0);
    const [wrongCnt, setWrongCnt] = useState(0);
    const [totalCnt, setTotalCnt] = useState(0);
    const [isPhraseMode, setIsPhraseMode] = React.useState(false);
    const [example, setExample] = useState('');
    const [history, setHistory] = useState<any[]>([]);
    const [newWords, setNewWords] = useState<string[]>([]);
    function nextWord() {
        index++;
        if (index < words.length) {
            currentWord = words[index];
            setWord('');
            setInputValue('');
            setExample('');
            setDesc(words[index].desc)
            if (!currentWord.isPhrase) speakWord(currentWord.word);
        } else if (failWords.length > 0) {
            currentWord = failWords[0];
            setWord('');
            setInputValue('');
            setExample('');
            setDesc(failWords[0].desc)
            if (!currentWord.isPhrase) speakWord(currentWord.word);
        } else {
            setWord('Finished!');
            setDesc('')
            setInputValue('');
            setExample('');
            index = -1;
            saveRemoteWords(words);
            saveHistory(words);
        }
        if (inputRef.current)
            inputRef.current.getElementsByTagName("input")[0].focus();
    }


    function checkWord() {
        let newWord = currentWord.word;
        setWord(newWord);
        setExample(currentWord.example || '')
        let innerIndex = index;

        // match currentWord with user input
        if (failWords.findIndex(word => word.word == currentWord.word) > -1) {
            failWords.splice(failWords.findIndex(word => word.word == currentWord.word), 1);
            innerIndex = words.findIndex(word => word.word == currentWord.word);
        }
        let newIsCurrect = isCorrect;
        let newCorrectCnt = correctCnt;
        let newWrongCnt = wrongCnt;

        if (currentWord.word == inputValue) {
            newIsCurrect = true;
            setIsCorrect(newIsCurrect);
            newCorrectCnt = correctCnt + 1;
            setCorrectCnt(newCorrectCnt);

            words[innerIndex].reviewCnt = (words[innerIndex].reviewCnt || 0) + 1;
            words[innerIndex].lastReviewDate = new Date().getTime();
            if (words[innerIndex].isPhrase) {
                words[innerIndex].nextReviewDate = getNextReviewDate(
                    Math.max(0, (words[innerIndex].reviewCnt || 0)
                        - Math.ceil(
                            (words[innerIndex].wrongCnt || 0) 
                        )
                    ) * 2
                );

            } else {
                words[innerIndex].nextReviewDate = getNextReviewDate(
                    Math.max(0, (words[innerIndex].reviewCnt || 0)
                        - Math.ceil(
                            (words[innerIndex].wrongCnt || 0)
                        )
                    )
                );

            }

        } else {
            newIsCurrect = false;
            setIsCorrect(false);
            newWrongCnt = wrongCnt + 1;
            setWrongCnt(newWrongCnt);

            words[innerIndex].wrongCnt = (words[innerIndex].wrongCnt || 0) + 1;

            failWords.push(currentWord);
        }
        speakWord(currentWord.word);
        currentWord = {};

        let status = {
            index: index,
            words: words,
            currentWord: currentWord,
            inputValue: inputValue,
            word: newWord,
            desc: desc,
            isCorrect: newIsCurrect,
            correctCnt: newCorrectCnt,
            wrongCnt: newWrongCnt,
            totalCnt: totalCnt,
            failWords: failWords,
            isPhraseMode: isPhraseMode
        }

        saveLocalResult(status);
    }


    const handleKeyDown = (event) => {
        if (index == -1) return
        if (event.key === 'Enter') {
            if (!currentWord.word) {
                nextWord();
            }
            if (word == '') {
                checkWord();
            }
        }
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handlePhraseModeChange = (event) => {
        setIsPhraseMode(event.target.checked);
    };

    function resumeStatus(tempStr) {
        if (!tempStr) {
            return;
        }
        let status = JSON.parse(tempStr);
        index = status.index;
        words = status.words;
        currentWord = status.currentWord;
        failWords = status.failWords;
        setWord(status.word);
        setInputValue(status.inputValue);
        setDesc(status.desc);
        setIsCorrect(status.isCorrect);
        setCorrectCnt(status.correctCnt);
        setWrongCnt(status.wrongCnt);
        setTotalCnt(status.totalCnt);
        setIsPhraseMode(status.isPhraseMode);
        
    }



    // 渲染完成
    function init() {
        setWord('Loading...');

        let tempStr = localStorage.getItem('status');
        if (tempStr) {
            resumeStatus(tempStr);
            return;
        }
        setCorrectCnt(0);
        setWrongCnt(0);

        loadWords(!isPhraseMode, isPhraseMode)
            .then((data: any) => {
                if (!data || !Array.isArray(data)) return;

                const CURRENT_TIME = new Date().getTime();
                data = data.filter((item) => { return !item.nextReviewDate || item.nextReviewDate < CURRENT_TIME })
                data.sort((a, b) => {
                    return (a.nextReviewDate ? a.nextReviewDate : (new Date().getTime() - 1)) - (b.nextReviewDate ? b.nextReviewDate : (new Date().getTime() - 1));
                })
                let wordNum = 0;
                let weight = 0;
                let tempIndex = 0;
                while (weight < WORD_COUNT_PERTEST * 100 && tempIndex < data.length) {
                    wordNum++;
                    weight += 100 / ((data[tempIndex].reviewCnt || 0) + 1);
                    tempIndex++;
                }
                words = data.slice(0, wordNum);
                setNewWords(words.filter(item => !item.lastReviewDate));
                setTotalCnt(words.length);
                nextWord();
            }).catch((err) => {
                console.log(err);
                alert(err);
            });
    }
    function showHistory() {
        loadWords(!isPhraseMode, isPhraseMode)
            .then((data: any) => {
                if (!data || !Array.isArray(data)) return;
                data.sort((a, b) => {
                    return (b.lastReviewDate ? b.lastReviewDate : 0) - (a.lastReviewDate ? a.lastReviewDate : 0);
                })
                data = data.map((item) => {
                    item.lastReviewDate = item.lastReviewDate ? new Date(item.lastReviewDate).toLocaleString() : "";
                    item.nextReviewDate = item.nextReviewDate ? new Date(item.nextReviewDate).toLocaleString() : "";
                    return item;
                });
                setHistory(data);
            });
    }

    function showWordList() {
        loadWords(!isPhraseMode, isPhraseMode)
            .then((data: any) => {
                if (!data || !Array.isArray(data)) return;

                data.sort((a, b) => {
                    return (b.lastReviewDate ? b.lastReviewDate : 0) - (a.lastReviewDate ? a.lastReviewDate : 0);
                })
            });
    }
    return (
        <section id="spelling" className="services" >
            <div className="section-title">
                <h2>单词听写</h2>
                <p></p>
            </div>

            <div style={
                { height: '', display: 'flex', flexDirection: 'column', alignItems: 'end', justifyContent: 'end', marginRight: '20px' }
            }>
                总数：{totalCnt}  正确:{correctCnt} 错误：{wrongCnt}
            </div>
            <div style={
                { height: '540px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }
            }>
                {index >= 0 && word != '' ? (isCorrect ? (<div style={{ color: 'green' }}>Yes！</div>) : (<div style={{ color: 'red' }}>No!</div>)) : null}
                <div style={{ ...styles.wordField }}> {word}</div>
                <div style={{ ...styles.wordField }}> {desc}</div>
                {((example && example != '') ?
                    <div>
                        {example.substring(0, example.indexOf(word))}<b style={{ color: 'blue', textDecoration: 'underline' }}>{word}</b>{example.substring(example.indexOf(word) + word.length)}
                    </div> : null)}
                {index < 0 ? (<FormControlLabel style={{ marginTop: '10px' }} labelPlacement="bottom" control={<Switch onChange={handlePhraseModeChange} checked={isPhraseMode} />} label={isPhraseMode ? "Phrase Mode" : "Word Mode"} />) : null}
                <History data={newWords} open={newWords.length != 0} onClose={() => setNewWords([])}
                            fields={{
                                keys: ['word', 'desc', 'example'],
                                names: ['Word', 'Description', 'Example']
                            }}
                            playField="word"
                            title='New Words Today'
                        />
                {index < 0 ? (<Button variant="contained" onClick={init} style={{ marginTop: '10px', marginBottom: '10px' }}>Start</Button>) :
                    <div>
                        {!currentWord.isPhrase ?
                            <IconButton onClick={() => {
                                speakWord(currentWord.word);
                                if (inputRef.current){
                                    inputRef.current.getElementsByTagName("input")[0].focus();
                                }
                            }}><PlayCircle color="primary"/> </IconButton>
                            : null
                        }
                    </div>
                }
                {index >= 0 ?
                    <TextField id="spellingInput" label="How to spell it?" variant="standard" style={styles.textField} onKeyDown={handleKeyDown} value={inputValue}
                        onChange={handleInputChange} ref={inputRef} autoComplete="off"
                    /> : null}
                {index < 0 ? (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <a href='javascript:void(0)' onClick={showHistory} >History</a>
                        <History data={history} open={history.length != 0} onClose={() => setHistory([])}
                            fields={{
                                keys: ['word', 'desc', 'lastReviewDate', 'reviewCnt'],
                                names: ['Word', 'Description', 'Last Reviewed Date', 'Reviewed Times']
                            }}
                            playField="word"
                        />
                        <Setting></Setting>
                    </div>) : null}
            </div>

        </section>
    )
}
export default Page;

// styles
const styles = {
    wordField: {
        marginTop: '10px',
    },
    button: {
        matchMedia: '(max-width: 600px)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '20px'
    },
    textField: {
        width: '200px',
        margin: '20px',
        TextAlign: 'center'
        // text align center


    },
}